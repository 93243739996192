@import 'sprite';

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  outline: none;
}

input[type='submit'],
button {
  cursor: pointer;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  text-align: inherit;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

i {
  display: inline-block;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.page-title {
  display: block;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #444444;
  margin-top: 40px;
  margin-bottom: 5px;
}
.page-desc {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #777777;
}

.close-btn-ico {
  position: absolute;
  right: -35px;
  top: -25px;
  display: inline-block;
  @include retina-sprite($ic-login-close-group);
}

.not-found {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;

  &:before {
    display: inline-block;
    content: '';
    width: 1px;
    height: 100%;
    vertical-align: middle;
  }

  > * {
    display: inline-block;
    vertical-align: middle;
  }
}
