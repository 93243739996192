// 자막 애니메이션
@keyframes flowingText {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

// marquee 애니메이션
@keyframes marquee {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-100%, 0);
  }
}

// 가사 모음 active 시 애니메이션
@keyframes pulse {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes ratate360deg {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes equallizer {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@keyframes progress {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.25;
  }
}

// 말하기 active 애니메이션
@keyframes followActive {
  0%,
  25%,
  50%,
  75%,
  100% {
    background: $purple-2;
    color: $white-0;
  }
}

// 말하기 active 애니메이션
@keyframes followTextActive {
  0%,
  25%,
  50%,
  75%,
  100% {
    color: $purple-2;
  }
}

// Toast
@keyframes toastAppAppear {
  0% {
    opacity: 0;
    bottom: -100px;
  }
  100% {
    opacity: 0.5;
    bottom: 128px;
  }
}

@keyframes toastAppDisAppear {
  0% {
    opacity: 0.5;
    bottom: 128px;
  }
  100% {
    opacity: 0;
    bottom: -100px;
  }
}

// 듣기 애니메이션
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
