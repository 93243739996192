@font-face {
  font-family: 'AppleSDGothicNeo';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local('AppleSDGothicNeo'),
    // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/bold/AppleSDGothicNeo.eot)
      //   format('eot'),
      // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/bold/AppleSDGothicNeo.eot?#iefix)
      //   format('embedded-opentype'),
      // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/bold/AppleSDGothicNeo.woff)
      //   format('woff'),
      // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/bold/AppleSDGothicNeo.ttf)
      //   format('truetype'),
      url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_bold_subset.woff2)format(
        'woff2'
      ),
    url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_bold_subset.woff)
      format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
  src: local('AppleSDGothicNeo'),
    // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/light/AppleSDGothicNeo.eot)
      //   format('eot'),
      // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/light/AppleSDGothicNeo.eot?#iefix)
      //   format('embedded-opentype'),
      // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/light/AppleSDGothicNeo.woff)
      //   format('woff'),
      // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/light/AppleSDGothicNeo.ttf)
      //   format('truetype'),
      url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_light_subset.woff2)format(
        'woff2'
      ),
    url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_light_subset.woff)
      format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local('AppleSDGothicNeo'),
    // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/regular/AppleSDGothicNeo.eot)
      //   format('eot'),
      // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/regular/AppleSDGothicNeo.eot?#iefix)
      //   format('embedded-opentype'),
      // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/regular/AppleSDGothicNeo.woff)
      //   format('woff'),
      // url(https://image.okaydoctor.co.kr/font/AppleSDGothicNeo/regular/AppleSDGothicNeo.ttf)
      //   format('truetype'),
      url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_regular_subset.woff2)format(
        'woff2'
      ),
    url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_regular_subset.woff)
      format('woff');
  font-display: swap;
}

br {
  font-family: Arial, Helvetica, sans-serif;
}
