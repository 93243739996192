@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@charset "UTF-8";
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_bold_subset.woff2) format("woff2"), url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_bold_subset.woff) format("woff");
  font-display: swap; }

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_light_subset.woff2) format("woff2"), url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_light_subset.woff) format("woff");
  font-display: swap; }

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_regular_subset.woff2) format("woff2"), url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_regular_subset.woff) format("woff");
  font-display: swap; }

br {
  font-family: Arial, Helvetica, sans-serif; }

/* color 정의 */
/* font-family */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-weight: normal;
  font-style: normal;
  src: url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_regular_subset.woff2) format("woff2"), url(https://cf.realclass.co.kr/qualson/images/AppleSDGothicNeo_regular_subset.woff) format("woff");
  font-display: swap; }

body {
  line-height: 1;
  font-family: 'AppleSDGothicNeo';
  font-display: swap; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*:before,
*:after {
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: inherit; }

input {
  outline: none; }

input[type='submit'],
button {
  cursor: pointer;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  text-align: inherit; }
  input[type='submit']::-moz-focus-inner,
  button::-moz-focus-inner {
    border: 0;
    padding: 0; }

i {
  display: inline-block; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.page-title {
  display: block;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #444444;
  margin-top: 40px;
  margin-bottom: 5px; }

.page-desc {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #777777; }

.close-btn-ico {
  position: absolute;
  right: -35px;
  top: -25px;
  display: inline-block;
  background-image: url(/static-web/media/sprite.100176a5.png);
  background-position: -569px -476px;
  width: 21px;
  height: 21px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .close-btn-ico {
      background-image: url(/static-web/media/sprite@2x.01f0e6de.png);
      background-size: 679px 658px; } }

.not-found {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center; }
  .not-found:before {
    display: inline-block;
    content: '';
    width: 1px;
    height: 100%;
    vertical-align: middle; }
  .not-found > * {
    display: inline-block;
    vertical-align: middle; }

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

/* Auto Height */
.swiper-container-autoheight {
  height: auto; }
  .swiper-container-autoheight .swiper-slide {
    height: auto; }
  .swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    -webkit-transition-property: -webkit-transform, height;
    -webkit-transition-property: height, -webkit-transform;
    transition-property: height, -webkit-transform;
    transition-property: transform, height;
    transition-property: transform, height, -webkit-transform; }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  touch-action: pan-y; }

.swiper-wp8-vertical {
  touch-action: pan-x; }

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 5px 0;
    display: block; }

.swiper-container-horizontal
> .swiper-pagination-bullets
.swiper-pagination-bullet {
  margin: 0 5px; }

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .swiper-pagination-progress .swiper-pagination-progressbar {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    transform-origin: left top; }

.swiper-container-rtl
.swiper-pagination-progress
.swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }
  .swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
    background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }
  .swiper-container-3d .swiper-wrapper,
  .swiper-container-3d .swiper-slide,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-3d .swiper-cube-shadow {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10; }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    /* Firefox 3.6-15 */
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */ }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    /* Firefox 3.6-15 */
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */ }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    /* Firefox 3.6-15 */
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */ }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    /* Firefox 3.6-15 */
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */ }

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  transition-property: opacity; }
  .swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none; }

.swiper-container-fade .swiper-slide-active {
  pointer-events: auto; }
  .swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }
  .swiper-lazy-preloader:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/* color 정의 */
/* font-family */
/* color 정의 */
/* font-family */
@-webkit-keyframes flowingText {
  from {
    width: 0%; }
  to {
    width: 100%; } }
@keyframes flowingText {
  from {
    width: 0%; }
  to {
    width: 100%; } }

@-webkit-keyframes marquee {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  to {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); } }

@keyframes marquee {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  to {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes ratate360deg {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes ratate360deg {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes equallizer {
  0% {
    height: 0; }
  100% {
    height: 100%; } }

@keyframes equallizer {
  0% {
    height: 0; }
  100% {
    height: 100%; } }

@-webkit-keyframes progress {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0.25; } }

@keyframes progress {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0.25; } }

@-webkit-keyframes followActive {
  0%,
  25%,
  50%,
  75%,
  100% {
    background: #6439fd;
    color: #fff; } }

@keyframes followActive {
  0%,
  25%,
  50%,
  75%,
  100% {
    background: #6439fd;
    color: #fff; } }

@-webkit-keyframes followTextActive {
  0%,
  25%,
  50%,
  75%,
  100% {
    color: #6439fd; } }

@keyframes followTextActive {
  0%,
  25%,
  50%,
  75%,
  100% {
    color: #6439fd; } }

@-webkit-keyframes toastAppAppear {
  0% {
    opacity: 0;
    bottom: -100px; }
  100% {
    opacity: 0.5;
    bottom: 128px; } }

@keyframes toastAppAppear {
  0% {
    opacity: 0;
    bottom: -100px; }
  100% {
    opacity: 0.5;
    bottom: 128px; } }

@-webkit-keyframes toastAppDisAppear {
  0% {
    opacity: 0.5;
    bottom: 128px; }
  100% {
    opacity: 0;
    bottom: -100px; } }

@keyframes toastAppDisAppear {
  0% {
    opacity: 0.5;
    bottom: 128px; }
  100% {
    opacity: 0;
    bottom: -100px; } }

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

.section_card {
  margin: 10px;
  padding: 10px;
  font-family: "AppleSDGothicNeo", "Apple SD Gothic Neo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: normal;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.08), 0 0 1px rgba(0, 0, 0, 0.02); }
  .section_card .oxford_wrap {
    display: none; }
  .section_card h3.u_hc,
  .section_card .pronun_wrap {
    display: none; }

.entry_search_word .blind {
  display: none; }

.entry_search_word .h_word .link_wrap {
  display: none; }

.entry_search_word .h_word .target {
  font-size: 20px;
  color: #005fc1; }

.entry_search_word .add_wordbook {
  display: none; }

.entry_search_word ul {
  margin-top: 10px; }

.entry_search_word li {
  margin-bottom: 5px; }
  .entry_search_word li .num {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle; }
  .entry_search_word li .desc {
    display: inline-block;
    vertical-align: middle; }
  .entry_search_word li .tag {
    display: inline-block;
    margin: 0px 6px 0 6px;
    padding: 0 7px;
    height: 16px;
    line-height: 17px;
    font-size: 10px;
    border-radius: 9px;
    white-space: nowrap; }
  .entry_search_word li .tag.blue {
    color: #46619e;
    border: 1px solid #46619e; }

.entry_search_word .shadowing_ico_default,
.entry_search_word .shadowing {
  display: none; }

/* color 정의 */
/* font-family */
/* color 정의 */
/* font-family */
@keyframes flowingText {
  from {
    width: 0%; }
  to {
    width: 100%; } }

@keyframes marquee {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  to {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ratate360deg {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes equallizer {
  0% {
    height: 0; }
  100% {
    height: 100%; } }

@keyframes progress {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0.25; } }

@keyframes followActive {
  0%,
  25%,
  50%,
  75%,
  100% {
    background: #6439fd;
    color: #fff; } }

@keyframes followTextActive {
  0%,
  25%,
  50%,
  75%,
  100% {
    color: #6439fd; } }

@keyframes toastAppAppear {
  0% {
    opacity: 0;
    bottom: -100px; }
  100% {
    opacity: 0.5;
    bottom: 128px; } }

@keyframes toastAppDisAppear {
  0% {
    opacity: 0.5;
    bottom: 128px; }
  100% {
    opacity: 0;
    bottom: -100px; } }

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

html,
body {
  /* display: flex;
  width: 100%; */
  width: 100%;
  /* height: 100%; */
  overflow-x: hidden;
  /* overflow: hidden; */
  /* height: auto;
  min-height: 100vh; */
  background: #ececec;
}

#root {
  /* display: flex; */
  width: 100%;
  /* height: 100%; */
  /* overflow: hidden; */
}

