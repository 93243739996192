/* color 정의 */

$white-0: #fff;
$orange-0: #ff6956;

// blue colors
$blue-0: #49d1fe;

// green colors
$green-0: #50e3c2;
$green-1: #29e3e2;
$green-2: #b4c6c2;

// black colors
$black-0: #2b2b2b;
$black-1: #333;
$black-2: #222;
$black-3: #000;
$black-4: #3e3e3e;
$black-5: #515151;

// purple colors
$purple-0: #3b3283;
$purple-1: #312a75;
$purple-2: #6439fd;
$purple-3: #c27cff;
$purple-4: #271d49;
$purple-5: #a8a1ec;

// grey colors
$grey-0: #979797;
$grey-1: #484848;
$grey-2: #666;
$grey-3: #d4d4d4;
$grey-4: #dbdbdb;
$grey-5: #bfbfbf;
$grey-6: #bcbcbc;
$grey-7: #999;
$grey-8: #888;
$grey-9: #cfcfcf;
$grey-10: #ebebeb;
$grey-11: #ececec;
$grey-12: #444;
$grey-13: #e1e1e1;
$grey-14: #f0f0f0;
$grey-15: #5e5e5e;
$grey-16: #f8f8f8; // 투명도 37%
$grey-17: #343434;
$grey-18: #9a9a9a;
$grey-19: #c3c3c3;
$grey-20: #b1b1b1;
$grey-21: #555;
$grey-22: #c8c8c8;
$grey-23: #e6e6e6;
$grey-24: #e1e2dd;
$grey-25: #777777;
$grey-26: #dadada;
$grey-27: #696969;
$grey-28: #c9c9c9;
$grey-29: #dcdcdc;
$grey-30: #c0bed5;
$grey-31: #d5d5d5;

$red-color-0: #ff6956;

$detail-bg-01: #d3b098; // raina
$detail-bg-02: #8f9db7; // eric
$detail-bg-03: #caadd9; // moon
$detail-bg-04: #a498cd; // jennifer
$detail-bg-05: #abc4c3; // park

$detail-point-01: #312a75; // raina
$detail-point-02: #49d1fe; // eric
$detail-point-03: #c27cff; // moon
$detail-point-04: #6439fd; // jennifer
$detail-point-05: #50e3c2; // park

/* font-family */
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
$font-style: 'AppleSDGothicNeo', 'Apple SD Gothic Neo', sans-serif;
$font-josefinSans: 'Josefin Sans', sans-serif;
$font-roboto: 'Roboto', sans-serif;

$desktop-video-height: 362px;
$desktop-video-player: 159px;
$desktop-sidebar-with: 0;
$desktop-webplayer-height: 80px;
$desktop-study-listHeight: 80px;
$desktop-complete-modal-header-height: 138px;
$desktop-complete-modal-content-height: 266px;

$monitor-width: 572px; // player-monitor
$music-info-height: 96px; // player-monitor footer info
$teacher-list-content-width: 1226px;

//selling

$violet-5f: #5f40f3;
$violet-30: #302b71;
$violet-25: #251e46;
$violet-f2: #f2efff;

$green-7d: #7de0c3;

//undefined
$green-b2: #b2e9ed;
$grey-ff: #ffffff;
$grey-9b: #9b9b9b;
$grey-ee: #eeeeee;
$grey-dc: #dcdcdc;
$grey-2e: #2e2e2e;
$grey-19: #191919;
$grey-00: #000000;
//background mobile product detail
$grey-f4: #f4f4f4;

//undefined - navigation
$grey-96: #969696;
$grey-85: #858585;
$grey-86: #868686;
// teacher navigator
$grey-b7: #b7b7b7;
$violet-7c: #7c61fe;
$grey-e8: #e8e8e8;

$violet-f2: #f2f0ff;

//undefiend - review
$grey-c0: #c0c0c0;
// Not in guide
$grey-21: #212121;
$grey-2b: #2b2b2b;
$grey-bd: #bdbdbd;
$grey-b5: #b5b5b5;
$grey-35: #353535;

$grey-35: #868686;

$grey-29: #292929;

$grey-2e: #2e2e2e;
$grey-e7: #e7e7e7;

$white-f6: #f6f6f6;

$ft-50: 50px;
$ft-45: 45px;
$ft-40: 40px;
$ft-30: 30px;
$ft-25: 25px;
$ft-18: 18px;

$ft-20: 20px;
$ft-16: 16px;

//mobile font size
$mf60: 30px;
$mf55: 27.5px;
$mf50: 25px;
$mf40: 20px;
$mf38: 19px;
$mf28: 14px;
$mf265: 13px;
$mf25: 12px;
$mf18: 9px;

$selling-width: 1366px;
$mobile-breakpoint-width: 750px;
$mobile-content-width: 320px;
