@import 'utils/all';

.section_card {
  margin: 10px;
  padding: 10px;
  @include handle-font(16px, 500, $black-3, normal);
  background: $white-0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.08), 0 0 1px rgba(0, 0, 0, 0.02);
  .oxford_wrap {
    display: none;
  }
  h3.u_hc,
  .pronun_wrap {
    display: none;
  }
}
.entry_search_word {
  .blind {
    display: none;
  }

  .h_word {
    .link_wrap {
      display: none;
    }
    .target {
      font-size: 20px;
      color: #005fc1;
    }
  }

  .add_wordbook {
    display: none;
  }

  ul {
    margin-top: 10px;
  }
  li {
    // display: flex;
    margin-bottom: 5px;
    .num {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
    }
    .desc {
      display: inline-block;
      vertical-align: middle;
    }
    .tag {
      display: inline-block;
      // vertical-align: top;
      margin: 0px 6px 0 6px;
      padding: 0 7px;
      height: 16px;
      line-height: 17px;
      font-size: 10px;
      border-radius: 9px;
      white-space: nowrap;
    }
    .tag.blue {
      color: #46619e;
      border: 1px solid #46619e;
    }
  }
  .shadowing_ico_default,
  .shadowing {
    display: none;
  }
}
