@import './variables';

@mixin handle-font($size, $weight, $color, $line-space: '') {
  font-family: $font-style;
  font-size: $size;
  font-weight: $weight;
  color: $color;
  line-height: $line-space;
}

@mixin handle-size($width, $height) {
  width: $width;
  height: $height;
}

// 벼튼 함수
@mixin handle-button($line-color, $line-round, $line-size: 1px, $bg-color: '') {
  border: $line-size solid $line-color;
  border-radius: $line-round;
  background-color: $bg-color;
  text-align: center;
  cursor: pointer;
}

// 말줄임 함수
@mixin handle-ellipsis($width) {
  width: $width;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// position: absolute 했을 경우 정중앙 정렬되는 함수
@mixin handle-position-center($width, $height) {
  @include handle-size($width, $height);
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: calc(-#{$width} / 2);
  margin-top: calc(-#{$height} / 2);
}

// dim 처리
@mixin handle-dim($bg, $opacity) {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $bg;
  opacity: $opacity;
}
